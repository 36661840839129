<style lang="less">
.meteriaType li {
  display: inline-block;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}
.meteriaType .color-li {
  width: 8px;
  height: 20px;
  background-color: #4285f4;
  margin-right: 3px;
}
</style>
<template>
  <div>
    <div class="text-right m-b-10">
      <Button type="success" size="small" @click="handleMeterialManage"
        >新素材上传</Button
      >
    </div>
    <div>
      <Divider dashed style="color: #fff">已有素材管理</Divider>
      <Row class="p-b-10">
        <i-col span="24">
          <label>素材类型：</label>
          <RadioGroup size="small" v-model="screenType" type="button">
            <Radio v-for="item in screenTypes" :key="item.id" :label="item.id">{{item.name}}</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="24">
          <label>素材状态：</label>
          <RadioGroup size="small" v-model="screenStatus" type="button">
            <Radio v-for="item in screenStatuss" :key="item.id" :label="item.id">{{item.name}}</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <i-table stripe :data="existsFile" :columns="fileColumns"></i-table>
       <div class="paging_style">
      <Page
        :total="total"
        :page-size="query.pageSize"
        show-total
        size="mini"
        :current="query.pageNumber"
        @on-change="handlePageNumberChange"
      ></Page>
    </div>
    </div>
    <!-- 上刊材料预览（画面、视频） -->
    <Modal v-model="visibleImage" width="1000" :footer-hide="true">
      <p slot="header">{{ fileName }}</p>
      <p v-if="approvaldesc">审批意见：{{ approvaldesc }}</p>
      <img
        :src="imageUrl + '?x-oss-process=image/resize,w_968'"
        v-if="visibleImage"
        style="width: 100%"
      />
    </Modal>
     <Modal
      v-model="visibleVideo"
      :footer-hide="true"
    >
      <p slot="header">{{fileName}}</p>
      <p v-if="approvaldesc">审批意见：{{ approvaldesc }}</p>
      <video
        :src="videoUrl"
        controls="controls"
        style="width:100%"
      >
        您的浏览器不支持预览视频。
      </video>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { agreefile, deletefile } from '@/api/msp/file'
import { downloadFileRequest } from '@/utils/download'
export default {
  mixins: [sysMixins],
  data () {
    return {
      total: 0,
      screenTypes: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '公益素材'
      }, {
        id: 2,
        name: '招商素材'
      }],
      screenType: 0,
      screenStatuss: [{
        id: 0,
        name: '全部'
      }, {
        id: 4,
        name: '审核中'
      }, {
        id: 5,
        name: '已通过'
      }, {
        id: 6,
        name: '已拒绝'
      }],
      screenStatus: 0,
      visibleImage: false,
      imageUrl: '',
      visibleVideo: false,
      videoUrl: '',
      fileName: '',
      approvaldesc: '',

      existsFile: [],
      fileColumns: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 32, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (data.row.mimeType.startsWith('video')) {
              return h('Icon', {
                props: { type: 'md-videocam', size: 32, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', {
                attrs: {
                  src: require('../../../assets/images/WinWordLogoSmall.scale-180.png'),
                  height: '50px'
                }
              })
            } else if (this.isMSExcel(data.row)) {
              return h('img', {
                attrs: {
                  src: require('../../../assets/images/ExcelLogoSmall.scale-180.png'),
                  height: '50px'
                }
              })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        // { title: '文件大小 (kb)', key: 'length' },
        { title: '状态', key: 'statusName' },
        {
          title: '操作',
          render: (h, data) => {
            let preview
            let deletebtn
            if (data.row.mimeType.startsWith('image') || data.row.mimeType.startsWith('video')) {
              preview = h(
                'a',
                {
                  style: { 'margin-left': '5px' },
                  on: {
                    click: () => {
                      if (data.row.type === 1) { this.handlePreview(data.row) } else if (data.row.type === 3) { this.handleVideoPreview(data.row) }
                    }
                  }
                },
                '预览'
              )
            }
            if ([5, 6].includes(data.row.status)) {
              deletebtn = h(
                'a',
                {
                  on: {
                    click: () => {
                      this.handleRemove(data.row)
                    }
                  }
                },
                '删除'
              )
            }
            return h('div', [
              deletebtn,
              h(
                'a',
                {
                  style: { 'margin-left': '5px' },
                  on: {
                    click: () => {
                      this.handleDownload(data.row)
                    }
                  }
                },
                '下载'
              ),
              preview
            ])
          }
        }
      ],
      query: {
        pageSize: 10,
        pageNumber: 1,
        fileTypes: '1,3'
      }
    }
  },
  computed: {},
  created () {
    this.getExistsFiles()
  },
  methods: {
    handelChangeScreenType () {

    },
    /**
     * 获取上传的素材
     */
    getExistsFiles () {
      this.existsFile = []
      this.query.status = this.screenStatus ? this.screenStatus : null
      this.query.type = this.screenType ? this.screenType : null
      agreefile(this.query).then((res) => {
        if (res.totalRow && res.list) {
          this.total = res.totalRow
          this.existsFile = res.list
        } else if (res.totalRow && !res.list) {
          this.query.pageNumber = this.query.pageNumber - 1
          this.getExistsFiles()
        }
      })
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = width === 0 ? '' : ',w_' + width
      const heightstr = height === 0 ? '' : ',h_' + height

      return (
        fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
      )
    },
    handlePreview (file) {
      this.imageUrl = file.fileKey
      this.fileName = file.fileName
      this.approvaldesc = file.approvaldesc
      this.visibleImage = true
    },
    handleVideoPreview (file) {
      this.videoUrl = file.fileKey
      this.fileName = file.fileName
      this.approvaldesc = file.approvaldesc
      this.visibleVideo = true
    },
    handleMeterialManage () {
      this.handlePage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'MaterialUpload', value: '素材管理', actived: true })
      this.setLinkRouterMaps([
        'MaterialUpload',
        () => {
          this.handlePage()
        }
      ])

      this.setActivedTagName('MaterialUpload')
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/file/downloadfile', { fileId: file.id }, file.fileName)
    },

    handleRemove (file) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除该素材，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          deletefile({ fileId: file.id }).then(res => {
            this.getExistsFiles()
            this.$Modal.remove()
          }).catch(() => {
            this.$Modal.remove()
          })
        }
      })
    },
    handlePage () {
      this.setBottomComponent('MaterialUpload')
      this.setLeftComponent()
      this.setRightComponent()
      this.setShowRightBlock(false)
      this.setShowLeftBlock(false)
    },
    reloadList () {
      this.query.pageNumber = 1
      this.getExistsFiles()
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getExistsFiles()
    }
  },
  watch: {
    screenType () {
      this.reloadList()
    },
    screenStatus () {
      this.reloadList()
    }
  }
}
</script>
